import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { v4 as uuidv4 } from "uuid";

import api from "@api/index";
import { IApiDataDto, IVenueScripts } from "@interfaces/rest/apiData.dto";
import { IApiDataConfig, IApiDataState, IStyleConfig } from "@interfaces/types/apiData.type";
import { ConfigClientEnum, ClientCompanyNameEnum, VenueNameEnum, IMemberVenueId } from "@interfaces/enums/apiData.enum";
import snStyles from "@theme/sn.json";
import cggStyles from "@theme/cgg.json";

const useConfigState = (): IApiDataState => {
  const [config, setConfig] = useState<IApiDataConfig | null>(null);
  const [script, setScript] = useState<IVenueScripts | null>(null);

  const [{}, getConfig] = useAxios<IApiDataDto>(
    {
      url: api.config,
      method: "get",
    },
    { manual: true },
  );

  const [{}, getTrackingScript] = useAxios<IVenueScripts>(
    {
      method: "get",
    },
    { manual: true },
  );

  useEffect(() => {
    getConfig()
      .then((response) => {
        const clientDomain = window.location.hostname.split(".")[0] as ConfigClientEnum;
        const beforeUrl: ConfigClientEnum = Object.keys(ConfigClientEnum).includes(clientDomain)
          ? clientDomain
          : ConfigClientEnum.sn;

        const client: ConfigClientEnum = window.location.hostname === "localhost" ? ConfigClientEnum.sn : beforeUrl;

        setConfig({
          API_URI: response.data.REACT_APP_TICKET_URL,
          client,
          companyName: ClientCompanyNameEnum[client],
          venueName: VenueNameEnum[client],
          azureInsightsKey: response.data.REACT_APP_INSIGHTS_KEY,
          gaMeasurementId: response.data.REACT_APP_GA[client],
          cartToken: uuidv4(),
        });

        return client;
      })
      .then((client: ConfigClientEnum) => {
        getTrackingScript({
          url: api.getTrackingScript(IMemberVenueId[client]),
        }).then((response) => {
          setScript(response.data);
        });
      });
  }, []);

  useEffect(() => {
    if (config?.client) {
      const themes = {
        sn: snStyles,
        cgg: cggStyles,
      };
      const currentThemeConfig: IStyleConfig = themes[config.client];
      type StyleConfigPropsArray = Array<keyof IStyleConfig>;
      const propsArray: StyleConfigPropsArray = Object.keys(currentThemeConfig) as StyleConfigPropsArray;
      const sheet = document.styleSheets[0];

      propsArray.forEach((keyName: keyof IStyleConfig) => {
        sheet.insertRule(`:root{${keyName}:${currentThemeConfig[keyName]}}`);
      });
    }
  }, [config?.client]);

  return {
    config,
    script,
  };
};

export default useConfigState;
