import React, { FC, useEffect, useRef } from "react";

import Loader from "@components/loader";
import { AppInsightsProvider } from "@providers/appInsightsProvider/AppInsightsProvider";

import AxiosClient from "./AxiosClient";
import useConfig from "./providers/configProvider/useConfig";
import AppRoutingModule from "./pages/AppRoutingModule";

const AuthenticationChecker: FC = () => {
  const { config, script } = useConfig();
  const headRef = useRef<Element | null>(document.getElementsByTagName("head")[0]);
  const bodyRef = useRef<Element | null>(document.getElementsByTagName("body")[0]);

  useEffect(() => {
    if (script?.htmlHeader) {
      headRef?.current?.append(document.createRange().createContextualFragment(script.htmlHeader));
    }
    if (script?.htmlFooter) {
      bodyRef?.current?.prepend(document.createRange().createContextualFragment(script.htmlFooter));
    }
  }, [script]);

  return !config ? (
    <Loader isAbsolute isHeightFull isWidthFull />
  ) : (
    <AxiosClient>
      <AppInsightsProvider>
        <AppRoutingModule />
      </AppInsightsProvider>
    </AxiosClient>
  );
};

export default AuthenticationChecker;
