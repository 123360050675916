import { useEffect, useState } from "react";

import { IDeviceMatchState } from "./deviceMatch.types";

const useIsDesktop = (): IDeviceMatchState => {
  const [deviceMatch, setDevicematch] = useState<IDeviceMatchState>({
    isMobile: window.matchMedia("(max-width: 600px)").matches,
    isTablet: window.matchMedia("(max-width: 968px)").matches,
  });

  useEffect(() => {
    window.matchMedia("(max-width: 600px)").addEventListener("change", (e) =>
      setDevicematch({
        isMobile: e.matches,
        isTablet: true,
      }),
    );

    window.matchMedia("(max-width: 968px)").addEventListener("change", (e) =>
      setDevicematch({
        isMobile: false,
        isTablet: e.matches,
      }),
    );
  }, []);

  return { ...deviceMatch };
};

export default useIsDesktop;
