import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

import { IAppInsightsState } from "./appInsights.types";

const useAppInsightsState = (azureInsightsKey?: string): IAppInsightsState => {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: azureInsightsKey,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });
  appInsights.loadAppInsights();

  return { reactPlugin };
};

export default useAppInsightsState;
