import React, { FC, createContext } from "react";

import useConfig from "@providers/configProvider/useConfig";

import useAppInsightsState from "./useAppInsightsState";

const AppInsightsContext = createContext<any | undefined>(undefined);

const AppInsightsProvider: FC = ({ children }) => {
  const { config } = useConfig();
  const { reactPlugin } = useAppInsightsState(config?.azureInsightsKey);

  return <AppInsightsContext.Provider value={{ reactPlugin }}>{children}</AppInsightsContext.Provider>;
};

export { AppInsightsProvider, AppInsightsContext };
