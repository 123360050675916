import { useContext } from "react";

import { IAppInsightsType } from "./appInsights.types";
import { AppInsightsContext } from "./AppInsightsProvider";

const useAppInsights = (): IAppInsightsType => {
  const context = useContext(AppInsightsContext);

  if (context === undefined) {
    throw new Error("useAppInsights must be used within a provider");
  }

  return context;
};

export default useAppInsights;
