import React, { FC, lazy, Suspense, useCallback } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

import Loader from "@components/loader";
import { DeviceMatchProvider } from "@providers/deviceMatchProvider/useDeviceMatchProvider";
import { SelfServiceProvider } from "@pages/selfService/provider/SelfServiceProvider";
import { ViewTicketsProvider } from "@pages/viewTickets/provider/ViewTicketsProvider";
import { TrackingProvider } from "@providers/trackingProvider/TrackingProvider";

import useAppInsights from "@providers/appInsightsProvider/useAppInsights";
import { CartProvider } from "./cart/provider/CartProvider";
import { ProductProvider } from "./product/provider/ProductProvider";
import { ProductsListProvider } from "./productsPreview/provider/ProductsListProvider";

const AppRoot = lazy(() => import("@components/appRoot"));
const ProductsPreview = lazy(() => import("./productsPreview"));
const Product = lazy(() => import("./product"));
const ProductsList = lazy(() => import("./productsPreview/components/productsList"));
const Cart = lazy(() => import("./cart"));
const Checkout = lazy(() => import("./checkout"));
const SelfService = lazy(() => import("./selfService"));
const ViewTickets = lazy(() => import("./viewTickets"));

const AppRoutingModule: FC = () => {
  const { reactPlugin } = useAppInsights();

  const handleBoundaryError = useCallback(() => <Loader isAbsolute isHeightFull isWidthFull />, []);

  return (
    <Suspense fallback={<Loader isAbsolute isHeightFull isWidthFull />}>
      <BrowserRouter>
        <AppInsightsErrorBoundary appInsights={reactPlugin} onError={handleBoundaryError}>
          <TrackingProvider>
            <ProductsListProvider>
              <CartProvider>
                <DeviceMatchProvider>
                  <Routes>
                    <Route path="/tickets" element={<ProductsPreview />}>
                      <Route path=":type" element={<ProductsList />} />
                    </Route>

                    <Route
                      path="/product"
                      element={
                        <ProductProvider>
                          <Product />
                        </ProductProvider>
                      }
                    />

                    <Route path="/cart" element={<Cart />} />

                    <Route path="/checkout" element={<Checkout />} />

                    <Route
                      path="/self-service"
                      element={
                        <SelfServiceProvider>
                          <SelfService />
                        </SelfServiceProvider>
                      }
                    />

                    <Route
                      path="/view-tickets"
                      element={
                        <ViewTicketsProvider>
                          <ViewTickets />
                        </ViewTicketsProvider>
                      }
                    />

                    <Route path="/" element={<AppRoot />} />
                  </Routes>
                </DeviceMatchProvider>
              </CartProvider>
            </ProductsListProvider>
          </TrackingProvider>
        </AppInsightsErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRoutingModule;
